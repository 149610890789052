// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false, 

  strapiUrl: 'https://services.triangular.media/api/', 
  strapiPublicClient: "d24ee281aa250865f177f2d492a1c2705a57d5ca1fbb35fc9cbf695593b5c7360f5383d172cdd4df5f4f0ed2feae32a4c1bfde51f713a1392f78101478e86e89ffcfc75800647778573c961e0eb91d6e20da1e7a44a11aef0a654c8f507613654aed9a2907274d4010fee9ece36b8ed9412ce95099b4b9d221ef8d94d8e74aa0",
  vimeoPlayer: 'https://player.vimeo.com/video/',
  
  sendgridUrl: 'https://api.sendgrid.com/',
  sendGridAPIKey: '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',

  appStore: 'https://apps.apple.com/us/app/triangular/id1623514894',
  googlePlay: 'https://play.google.com/store/apps/dev?id=6577204690045492686&hl=es&gl=US',
  webAPP: 'https://app.triangular.media/',
  webURL: 'https://triangularspace.com/',

  firebaseConfig: {
    apiKey: "AIzaSyDR3iwWarHKP7_xUgwbMXx9gBA8NNIcYek",
    authDomain: "triangularapp-356402.firebaseapp.com",
    projectId: "triangularapp-356402",
    storageBucket: "triangularapp-356402.appspot.com",
    messagingSenderId: "204592574635",
    appId: "1:204592574635:web:0df11ec51405d7609e47ee",
    measurementId: "G-QTM2X67Y9Z"
  },

  facebookConfig:{
    appId: "1582919035436047",
    appName: "TriangularFcb",
    secret: "9500f2274fe055247697d4583bc4f529"
  },

  stripePublicableKey: "pk_test_51KZdDHEBpr7qocXImq6C3pUJpixmbsPNk4YdrM4LvKKRDyNnwFZi569HMznkPlaPeGRkWtgsgSMorC8IZsxWM6rZ008aEJutuc",
  stripeSecretKey: "sk_test_51KZdDHEBpr7qocXIg6NZTjTruIZUC5NWUrudEobOFIGBiJlgzgYY9851Rsodwr5FUH0Ihy4NLVaW5k6Kq46YZMSe00zb2esP0l",
  stripeUrl: 'https://api.stripe.com/v1/'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
