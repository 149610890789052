import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoggedinGuardService } from '../app/services/auth/loggedin-guard.service';
import { SignGuardService } from './services/auth/sign-guard.service';


const routes: Routes = [
  { path: '', redirectTo: '/begin', pathMatch: 'full' },

  //// Auth
  {
    path: 'begin',
    loadChildren: () => import('./pages/auth/begin/begin.module').then( m => m.BeginPageModule),
    canActivate: [ SignGuardService ]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then( m => m.RegisterPageModule),
    canActivate: [ SignGuardService ]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule),
    canActivate: [ SignGuardService ]
  },
  {
    path: 'register-success',
    loadChildren: () => import('./pages/auth/register-success/register-success.module').then( m => m.RegisterSuccessPageModule),
    // canActivate: [ SignGuardService ]
  },
  {
    path: 'mail-forgot',
    loadChildren: () => import('./pages/auth/mail-forgot/mail-forgot.module').then( m => m.MailForgotPageModule),
    canActivate: [ SignGuardService ]
  },
  {
    path: 'reset-password/:code',//'reset-password/:code',
    loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },

  //// END Auth   SecureInnerPagesGuard
  {
    path: 'video-detail/:videoId',
    loadChildren: () => import('./pages/video-detail/video-detail.module').then( m => m.VideoDetailPageModule),
    // canActivate: [ LoggedinGuardService ]
  },
  {
    path: 'video-player/:videoId/:videoType/:continue',
    loadChildren: () => import('./pages/video-player/video-player.module').then( m => m.VideoPlayerPageModule),
    // canActivate: [ LoggedinGuardService ]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [ LoggedinGuardService ]
  },
  {
    path: 'mydata',
    loadChildren: () => import('./pages/account/mydata/mydata.module').then( m => m.MydataPageModule),
    canActivate: [ LoggedinGuardService ]
  },
  {
    path: 'mysubscription',
    loadChildren: () => import('./pages/account/mysuscription/mysuscription.module').then( m => m.MysuscriptionPageModule),
    canActivate: [ LoggedinGuardService ]
  },
  {
    path: 'premium-success',
    loadChildren: () => import('./pages/suscription/premium-success/premium-success.module').then( m => m.PremiumSuccessPageModule),
    canActivate: [ LoggedinGuardService ]
  },
  {
    path: 'mod-mydata',
    loadChildren: () => import('./pages/account/mod-mydata/mod-mydata.module').then( m => m.ModMydataPageModule),
    canActivate: [ LoggedinGuardService ]
  },
  /// pages without auth
  {
    path: 'about-us',
    loadChildren: () => import('./pages/company/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'legal-info',
    loadChildren: () => import('./pages/company/legal-info/legal-info.module').then( m => m.LegalInfoPageModule)
  },
  {
    path: 'email-confim',
    loadChildren: () => import('./pages/auth/email-confim/email-confim.module').then( m => m.EmailConfimPageModule)
  },
  {
    path: 'register-succes-mail-confirm',
    loadChildren: () => import('./pages/auth/register-succes-mail-confirm/register-succes-mail-confirm.module').then( m => m.RegisterSuccesMailConfirmPageModule)
  },
  {
    path: 'get-subscription-android',
    loadChildren: () => import('./pages/suscription/get-suscription-android/get-suscription-android.module').then( m => m.GetSuscriptionAndroidPageModule)
  },
  {
    path: 'get-subscription-apple',
    loadChildren: () => import('./pages/suscription/get-suscription-apple/get-suscription-apple.module').then( m => m.GetSuscriptionApplePageModule)
  },
  {
    path: 'video-offline',
    loadChildren: () => import('./pages/video-offline/video-offline/video-offline.module').then( m => m.VideoOfflineModule)
  },
  {
    path: 'video-search',
    loadChildren: () => import('./pages/video-search/video-search.module').then( m => m.VideoSearchModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
