import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { AuthenticationService } from './auth/authentication.service';

interface AuthResponse {
  jwt: string;
  user: User;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  data = 'users';
  url = environment.strapiUrl + this.data;

  constructor(
    private httpClient: HttpClient,
    private auth: AuthenticationService
  ) {
    //console.log(this.url);
  }


  getPublicAuthHeader() {
    return {
      headers: { 'Authorization': `Bearer ${environment.strapiPublicClient}` }
    };
  };
  
  // Create a new item
  createItem(item) {
    return this.httpClient
      .post(this.url, item, this.auth.getAuthHeader())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Get single Item data by ID
  getMe(): Observable<any> {
    return this.httpClient
      .get<any>(this.url + '/me' + "?populate=*", this.auth.getAuthHeader())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Get single Item data by ID
  getItem(id): Observable<any> {
    return this.httpClient
      .get<any>(this.url + '/' + id + "?populate=*", this.auth.getAuthHeader())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Get Items data
  getList(): Observable<any> {
    return this.httpClient
      .get<any>(this.url +"?populate=*" + "&filters[active]=true + &sort[order]=desc", this.auth.getAuthHeader())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Get Items data
  getListbyEmail(emailFunc:string): Observable<any> {
    return this.httpClient
      .get<any>(this.url +"?filters[email]=" + emailFunc,this.getPublicAuthHeader())
      .pipe(
        catchError(this.handleError)
      );
  }

  // Update item by id
  updateItem(id, item): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/' + id, item, this.auth.getAuthHeader())
      .pipe(
        catchError(this.handleError)
      );
  }

  
  updateUserStatus (id:number, appRole: string, premiumPeriod: string) {
    return this.httpClient.put<AuthResponse>(
      `${environment.strapiUrl}users/` + id,
      { appRole, premiumPeriod }, this.auth.getAuthHeader()
    ).pipe(
      catchError(this.handleError)
    );
  }
  


  // Delete item by id
  deleteItem(id) {

    return this.httpClient
      .delete<any>(this.url + '/' + id, this.auth.getAuthHeader())
      .pipe(
        catchError(this.handleError)
      );
    
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
