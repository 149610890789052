import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private namespace = "triangularAPP";

  constructor() { }

  getItem(key: string): string | null {
    return window.localStorage.getItem(`${this.namespace}_${key}`);
  }

  setItem(key: string, value: string) {
    window.localStorage.setItem(`${this.namespace}_${key}`, value);
  }

  getArray(key: string): any | null {
    return window.localStorage.getItem(`${this.namespace}_${key}`);
  }

  setArray(key: string, value: any) {
    window.localStorage.setItem(`${this.namespace}_${key}`, value);
  }

  removeItem(key: string) {
    window.localStorage.removeItem(`${this.namespace}_${key}`);
  }

  clear() {
    window.localStorage.clear();
  }
}
