import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';

import { Storage } from '@ionic/storage-angular';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

// Deep Links
import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { environment } from 'src/environments/environment';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from './shared/translateloader';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
//import { VideoPlayer } from '@ionic-native/video-player/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    IonicStorageModule.forRoot(),
    IonicModule.forRoot({
      mode: 'ios'
    }),
    TranslateModule.forRoot({
      defaultLanguage:'es',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    BrowserModule, 
    AppRoutingModule
  ],
  providers: [
    InAppPurchase2,
    ScreenOrientation,
    SocialSharing, 
    FirebaseAnalytics,
    FileOpener,
    //VideoPlayer,
    File,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
    
})
export class AppModule {

  constructor(
    private storage: Storage,
    private router: Router,
    private zone: NgZone
  ) {
    
      this.storage.create();
      this.initializeApp();
      
  }

  // Deep Links
  initializeApp() {
   
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {

            // FORMA DE HACERLO DESDE https://www.youtube.com/watch?v=tAQwllZSQD8

            const domain = 'app.triangular.media';
            const pathArray = event.url.split(domain);
            // PathArray is now like ['https://app.triangular.media', 'details/42' ]

            // Get the lastest element with pop
            const appPath = pathArray.pop();

            if (appPath) {
                this.router.navigateByUrl(appPath);
            }
            // If no match, do nothing - let regular routing
            // logic take over

            /*
            OTRA FORMA DE HACERLO DESDE ANGULAR
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            const slug = event.url.split(".app").pop();
            if (slug) {
                this.router.navigateByUrl(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
            */
        });
    });
  }

}
