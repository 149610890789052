import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { environment } from '../../../environments/environment'
import { StorageService } from './storage.service';
import { Observable, throwError } from 'rxjs';


interface AuthResponse {
  jwt: string;
  user: User;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private url = `${environment.strapiUrl}auth/local`;
  private urlForgot = `${environment.strapiUrl}auth/forgot-password`;
  private urlReset = `${environment.strapiUrl}auth/reset-password`;

  private loginTracker = new BehaviorSubject(this.checkIfLoggedIn());

  loggedInStatus$ = this.loginTracker.asObservable();

  constructor(
    private http: HttpClient, 
    private ss: StorageService,
    private router: Router
  ) { }

  getPublicAuthHeader() {
    return {
      headers: { 'Authorization': `Bearer ${environment.strapiPublicClient}` }
    };
  };

  login(identifier: string, password: string) {
    return this.http.post<AuthResponse>(
      this.url, 
      { identifier, password }
    ).pipe(
      catchError(this.handleError)
    );
  }

  register(alias: string, username: string, email: string, password: string) {
    return this.http.post<AuthResponse>(
      `${this.url}/register`,
      { alias, username, email, password }
    ).pipe(
      catchError(this.handleError)
    );
  }

  /*
  createItem(item): Observable<any> {
    return this.httpClient
      .post(`${this.url}`, item, this.auth.getAuthHeader())
      .pipe(
        catchError(this.handleError)
      );
  };
  */

  updateUser (id:number, alias: string, username: string, email: string, password: string ) {
    
    const data = (password) ? { alias, username, email, password } : { alias, username, email };
    
    return this.http.put<any>(
      `${environment.strapiUrl}users/` + id,
      data,
      this.getAuthHeader()
    ).pipe(
      catchError(this.handleError)
    );
  }

  updateAvatar (id:number, avatar: string ) {
    return this.http.put<any>(
      `${environment.strapiUrl}users/` + id,
      { avatar },
      this.getAuthHeader()
    ).pipe(
      catchError(this.handleError)
    );
  }

  forgotPass (email: string) {
    return this.http.post<AuthResponse>(
      `${this.urlForgot}`, 
      { email }
    ).pipe(
      catchError(this.handleError)
    );
  }

  resetPass (password: string, passwordConfirmation: string, code:string) {
    return this.http.post<AuthResponse>(
      `${this.urlReset}`, 
      { password, passwordConfirmation, code }
    ).pipe(
      catchError(this.handleError)
    );
  }

  checkIfLoggedIn() {
    return this.ss.getItem('loggedIn') === 'true';
  }

  persistUser(resp: AuthResponse) {
    [
      ['userId', resp.user.id],
      ['alias', resp.user.alias],
      ['userEmail', resp.user.email],
      ['username', resp.user.username],
      ['appRole', resp.user.appRole],
      ['premiumPeriod', resp.user.premiumPeriod],      
      ['avatar', resp.user.avatar],

      ['loggedIn', 'true'],
      ['token', resp.jwt]
    ].forEach(item => this.ss.setItem(item[0], item[1]));

    this.loginTracker.next(true);
  }


  persistUserField(key: string, value: any) {
    this.ss.setItem(key, value);
  }

  /*
  persistInfoUserReg(resp) {
    console.log (resp);
    // data.attributes.appRole    
    [
      ['appRole', resp.data.attributes.appRole],
      ['premiumPeriod', resp.data.attributes.premiumPeriod]
    ].forEach(item => this.ss.setItem(item[0], item[1]));

    this.loginTracker.next(true);
  }

  persistInfoUserLog(resp) {
    console.log (resp);
    // data.attributes.appRole    
    [
      ['appRole', resp.data[0].attributes.appRole],
      ['premiumPeriod', resp.data[0].attributes.premiumPeriod]
    ].forEach(item => this.ss.setItem(item[0], item[1]));

    this.loginTracker.next(true);
  }
  */

  getPersistedUser(): any {
    return {
      id: this.ss.getItem('userId') || '',
      alias: this.ss.getItem('alias') || '',
      username: this.ss.getItem('username') || '',
      email: this.ss.getItem('userEmail') || '',
      appRole: this.ss.getItem('appRole') || '',
      premiumPeriod: this.ss.getItem('premiumPeriod') || '',
      avatar : this.ss.getItem('avatar')
    };
  }

  getPersistedToken(): string {
    return this.ss.getItem('token') || '';
  }

  logout() {
    ['userId', 
    'userEmail', 
    'username', 
    'avatar',
    'loggedIn', 
    'appRole',
    'premiumPeriod',
    'alias',
    'token'].forEach(item => this.ss.removeItem(item));

    localStorage.clear();
    this.loginTracker.next(false);
    this.router.navigate(['/begin'])
  }

  getAuthHeader() {
    return {
      headers: { 'Authorization': `Bearer ${this.getPersistedToken()}` }
    };
  };

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
  
}