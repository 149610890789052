import { Component, NgZone, OnInit } from '@angular/core';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { AuthenticationService } from './services/auth/authentication.service';
import { environment } from 'src/environments/environment';
import { enableDebugTools } from '@angular/platform-browser';
import { RouterEvent, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { UsersService } from './services/users.service';
import { User } from './models/user';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  userActive: any;
  userAvatar: any;
  hasAvatar = false;

  activeUser: any;
  isLogged:boolean;
  isPremium: any;
  
  // Por definicion en google se puso como mensual el ID del producto de suscripcion Premium
  // semestralProductKey: any = "Semestral";
  monthlyProductKey: any = "mensual";
  anualProductKey: any = "premium";

  refreshVideos$ = new Subject<string>();

  constructor(
    private auth: AuthenticationService,
    private socialSharing: SocialSharing,
    private platform: Platform,
    private store: InAppPurchase2,
    public menu: MenuController,
    private router: Router,
    private translate: TranslateService,
    private zone: NgZone,
    private userService: UsersService
  ) {
    // deapLink
    this.initializeApp();

    ////// Load User Data 4 APP
    this.loadDataUser();

    this.platform.ready().then(() => {

      // no genera compras, DEBUGUEA
      this.store.verbosity = this.store.DEBUG;

      // this.store.validator = "https://billing.fovea.cc/";

      this.registerProducts();
      
      // Get the real product information
      this.store.ready(() => {
        
        this.store.get(this.monthlyProductKey);
        this.store.get(this.anualProductKey);
        
      });
      
    });

    SplashScreen.show({
      showDuration: 6000,
      autoHide: true
    });
  }
  changeLanguage(e: any) {
    this.translate.use(e.detail.value);
    this.menu.close();
    this.refreshVideos$.next(e.detail.value);
  }

  updateAvatar(avatar) {
    this.userAvatar = avatar;
  }

  updateUser() {
    this.userActive = this.auth.getPersistedUser();
  }

  registerProducts(){

    if (this.platform.is('ios')) {
      this.monthlyProductKey = "Mensual";
      this.anualProductKey = "Anual";
    }
    
    /// register mensual
    this.store.register([{
      id: this.monthlyProductKey,
      type: this.store.PAID_SUBSCRIPTION,
    },{
      id: this.anualProductKey,
      type: this.store.PAID_SUBSCRIPTION,
    }]);

    this.store.refresh();
  }


  loadDataUser() {
    // console.log('disparado');
    this.isLogged = this.auth.checkIfLoggedIn();

    if (this.isLogged) {
      // console.log('logueado');
      this.userActive = this.auth.getPersistedUser();
      this.userAvatar = this.userActive.avatar;
      this.hasAvatar =  this.userActive.avatar && this.userActive.avatar !== '' && this.userActive.avatar !== 'null';
      this.isPremium = (this.userActive.appRole === 'Premium')? true : false;
      // console.log('this.isPremium',this.isPremium)
      // console.log('userActive',this.userActive);
    } else {
      // console.log('no logueado');
    }
  }

  ngOnInit(): void {

    // Si abrimos y estamos logeado, actualizamos perfil de servidor no sea que haya cambiado algo
    this.isLogged = this.auth.checkIfLoggedIn();

    if (this.isLogged) {
      this.userService.getMe().subscribe((result : User) => {
        this.auth.persistUserField('alias', result.alias);
        this.auth.persistUserField('premiumPeriod', result.premiumPeriod);
        this.auth.persistUserField('appRole', result.appRole);
        this.activeUser = this.auth.getPersistedUser();
      });
    }
  }

  getSubscription(){

    if (this.platform.is('ios')) { // APPLE
      this.menu.close();
      this.router.navigate(['/get-subscription-apple']);
    } else if (this.platform.is('android')) { // ANDROID
      this.menu.close();
      this.router.navigate(['/get-subscription-android']);
    } else { // OTRO SISTEMA
      this.menu.close();
      this.router.navigate(['/get-subscription-android']);
    }
    
  }

  ionViewWillEnter() {    
  }

  onClickShare(){

    // Share via email

    let urlShare = environment.webAPP;

    if (this.platform.is('ios')) {
      urlShare = environment.appStore;
    } else if (this.platform.is('android')) {
      urlShare = environment.googlePlay;
    } else {
      urlShare = environment.webAPP;
    }

    const subject = this.translate.instant('home.share.subject'); // 'Instala Triangular, Streaming consciente.';
    const message = this.translate.instant('home.share.message'); // 'DEJA QUE ESTE TESORO TE ENTREGUE SUS JOYAS';

    this.socialSharing.share(
        message,
        subject, 
        '',
        urlShare
      ).then(() => {
        console.log('disparo compartir');
      });

  }

  logOutSession(){
    this.auth.logout();
    // console.log ('salir')
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.zone.run(() => {
        const domain = 'app.triangular.media';
				const pathArray = event.url.split(domain);
				const appPath = pathArray.pop();
				if (appPath) {
					this.router.navigateByUrl(appPath);
				}
			});
		});
	}

}